
$break-xmedium: 620px;

.Sidebar {
  width: 260px;
  padding: 32px 0 0 0;
  margin-bottom: 80px;
  margin-left: -24px;

  @media (max-width: $break-medium) {
    width: 100%;
    padding-top: 40px;
    margin-bottom: 0;
    margin-left: 0;

    &__content {
      position: relative;
    }
  }

  &__container {
    position: sticky;
    top: 88px;
    overflow-y: auto;
    padding: 24px;
    @include scrollbar(4px, auto, var(--clr-blue-light), var(--clr-blue));
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in-out 300ms, transform ease-in-out 300ms;
    transform: translate3d(15%, 0, 0);

    @media (max-width: $break-medium) {
      position: static;
      height: auto !important;
      padding: 0;
    }
  }

  &__inner {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__news {
    border-radius: 4px;
    background-color: var(--clr-white);
    padding: 16px;
    cursor: pointer;
    border: 1px solid var(--clr-separator);
    display: block;

    @include dark-theme {
      background: var(--clr-navigation);
    }

    &:hover h4 {
      text-decoration: underline;
    }

    @media (max-width: $break-medium) {
      margin-bottom: 40px;
    }

    @media (max-width: $break-xmedium) {
      margin-bottom: 0;
    }

    small {
      display: block;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-tercial);
      text-transform: uppercase;
      padding-bottom: 2px;
    }

    h4 {
      display: block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.25;
      color: var(--clr-blue);
    }

    p {
      display: block;
      font-size: 0.875rem;
      line-height: 1.4285714286;
      color: var(--clr-text-primary);
      padding-top: 8px;
    }
  }

  &__weather {
    display: block;
    padding: 16px 0 16px 48px;
    border-bottom: 1px solid var(--clr-separator);
    position: relative;
    cursor: pointer;
    transition: all .2s ease-in-out;

    @media (max-width: $break-medium) {
      float: left;
      width: calc(33.33% - 24px);
      height: 104px;
      border: 1px solid var(--clr-separator);
      margin-right: 24px;
      background-position: 24px center;
      padding: 33px 72px 34px 72px;
      border-radius: 4px;

      &:hover {
        transform: scale3d(1.02, 1.02, 1);
        box-shadow: rgba(0, 41, 69, 0.24) 0 4px 16px 0;
      }
    }

    @media (max-width: 768px) {
      padding-right: 24px;
    }

    @media (max-width: $break-xmedium) {
      float: none;
      width: 100%;
      height: auto;
      padding: 16px 48px;
      border: none;
      border-bottom: 1px solid var(--clr-separator);
      background-position: left;

      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    &--01d,
    &--01n {
      background: $ic-weather-clear-sky no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-clear-sky--dark;
      }
    }

    &--02d,
    &--02n {
      background: $ic-weather-few-clouds no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-few-clouds--dark;
      }
    }

    &--03d,
    &--03n {
      background: $ic-weather-scattered-clouds no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-scattered-clouds--dark;
      }
    }

    &--04d,
    &--04n {
      background: $ic-weather-broken-clouds no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-broken-clouds--dark;
      }
    }

    &--09d,
    &--09n {
      background: $ic-weather-shower-rain no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-shower-rain--dark;
      }
    }

    &--10d,
    &--10n {
      background: $ic-weather-rain no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-rain--dark;
      }
    }

    &--11d,
    &--11n {
      background: $ic-weather-thunderstorm no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-thunderstorm--dark;
      }
    }

    &--13d,
    &--13n {
      background: $ic-weather-snow no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-snow--dark;
      }
    }

    &--50d,
    &--50n {
      background: $ic-weather-mist no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-weather-mist--dark;
      }
    }

    @media (max-width: $break-medium) {
      background-position: 16px center;
    }

    @media (max-width: $break-xmedium) {
      background-position: left center;
    }

    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 24px;
      right: 0;
      background: $ic-link no-repeat center;
      background-size: 24px 24px;

      @media (max-width: $break-medium) {
        right: 24px;
        top: 40px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      @media (max-width: $break-xmedium) {
        display: block;
        top: 24px;
        right: 0;
      }
    }

    &Temperature {
      display: block;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &Condition {
      display: block;
      font-size: 0.8125rem;
      line-height: 1;
      color: var(--clr-text-secondary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__travel {
    display: block;
    padding: 16px 0 16px 48px;
    border-bottom: 1px solid var(--clr-separator);
    position: relative;
    cursor: pointer;
    background: $ic-location no-repeat left;
    background-size: 40px 40px;
    margin-bottom: 16px;
    transition: all .2s ease-in-out;

    @media (max-width: $break-medium) {
      float: left;
      width: calc(33.33% - 24px);
      height: 104px;
      border: 1px solid var(--clr-separator);
      background-position: 24px center;
      margin-right: 24px;
      padding: 33px 72px 34px 72px;
      border-radius: 4px;

      &:hover {
        transform: scale3d(1.02, 1.02, 1);
        box-shadow: rgba(0, 41, 69, 0.24) 0 4px 16px 0;
      }
    }

    @media (max-width: 768px) {
      padding-right: 24px;
    }

    @media (max-width: $break-xmedium) {
      float: none;
      width: 100%;
      height: auto;
      padding: 16px 48px;
      border: none;
      border-bottom: 1px solid var(--clr-separator);
      background-position: left;

      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    &--driving {
      background: $ic-travel-driving no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-travel-driving--dark;
      }

      @media (max-width: $break-medium) {
        background-position: 16px center;
      }

      @media (max-width: $break-xmedium) {
        background-position: left center;
      }
    }

    &--walking {
      background: $ic-travel-walking no-repeat left;
      background-size: 40px 40px;

      @include dark-theme {
        background-image: $ic-travel-walking--dark;
      }

      @media (max-width: $break-medium) {
        background-position: 16px center;
      }

      @media (max-width: $break-xmedium) {
        background-position: left center;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 24px;
      right: 0;
      background: $ic-link no-repeat center;
      background-size: 24px 24px;

      @media (max-width: $break-medium) {
        right: 24px;
        top: 40px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      @media (max-width: $break-xmedium) {
        display: block;
        top: 24px;
        right: 0;
      }
    }

    &Time {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &Distance {
      font-size: 0.8125rem;
      line-height: 1;
      color: var(--clr-text-secondary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__attributes {
    display: block;

    @media (max-width: $break-medium) {
      float: left;
      width: 50%;
    }

    @media (max-width: $break-xmedium) {
      float: none;
      width: 100%;
    }
  }

  &__attribute {
    display: block;
    font-size: 0.8125rem;
    color: var(--clr-text-secondary);
    line-height: 1.8461538462;
    min-height: 24px;
    padding-left: 48px;
    margin-top: 8px;
    background: $ic-cross no-repeat 12px 2px;
    background-size: 20px 20px;

    &--checked {
      font-weight: bold;
      color: var(--clr-text-primary);
      background: $ic-check no-repeat 12px 2px;
      background-size: 20px 20px;

      @include dark-theme {
        background-image: $ic-check--dark;
      }
    }
  }

  &__map {
    display: block;
    height: 104px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: var(--clr-text-tercial);
    background-size: cover;
    overflow: hidden;
    margin-top: 16px;

    @media (max-width: $break-medium) {
      position: relative;
      width: 33%;
      margin-top: 0;
    }

    @media (max-width: $break-xmedium) {
      position: relative;
      width: 100%;
      margin-top: 16px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
    }

    &Link {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.4285714286;
      text-align: center;
      color: var(--clr-white);
      background: var(--clr-red);
      text-transform: uppercase;
      padding: 7px 24px 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &:hover {
      transform: scale3d(1.02, 1.02, 1);
      box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.64);
    }
  }
}
