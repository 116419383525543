.Map {
  margin-top: 32px;

  &__content {
    width: calc(100% + #{$container-padding-xlarge});
    position: relative;
    left: -$container-padding-xlarge;
    height: 440px;
    background: var(--clr-separator);

    @media (max-width: $break-large) {
      width: calc(100% + (100vw - 100%) / 2);
      left: calc((100vw - 100%) / -2);
    }

    @media (max-width: $break-medium) {
      width: calc(100% + #{$container-padding-large * 2});
      left: -$container-padding-large
    }

    @media (max-width: $break-small) {
      height: 320px;
      width: calc(100% + #{$container-padding-small * 2});
      left: -$container-padding-small
    }

    div {
      border-color: transparent !important;
    }
  }

  &__types {
    display: block;
    @include clear;

    @media (max-width: $break-small) {
      float: right;
      text-align: right;
      margin-right: -16px;
    }
  }

  &__type {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 24px;
    color: var(--clr-blue);
    height: 32px;
    padding: 4px 24px;
    text-transform: uppercase;
    float: right;
    cursor: pointer;

    &--active {
      color: var(--clr-white);
      background: var(--clr-blue);
    }

    @media (max-width: $break-small) {
      float: none;
      height: 26px;
      font-size: 0.75rem;
      line-height: 20px;
      padding: 3px 16px;
    }
  }

  &__box {
    display: block;
    font-family: -apple-system, '.SFNSDisplay-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border-radius: 8px !important;
    overflow: hidden;

    &Title {
      display: inline-block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5;
      color: var(--clr-blue);

      &:hover {
        text-decoration: underline;
      }

      &--external {
        position: relative;
        padding-right: 20px;

        &:after {
          content: "";
          display: block;
          background: $ic-link no-repeat center;
          background-size: 12px 12px;
          width: 12px;
          height: 12px;
          position: absolute;
          right: 0;
          top: 6px;
        }
      }

      &--noLink {
        cursor: default;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &Description {
      display: block;
      font-size: 0.875rem;
      line-height: 1.4285714286;
      color: var(--clr-black);
      font-weight: 400;
    }
  }
}

.gm-style .gm-style-iw-t::after {
  top: -12px;
  background: linear-gradient(45deg, rgba(37, 37, 37, 1) 50%, rgba(37, 37, 37, 0) 51%, rgba(37, 37, 37, 0) 100%);
}

.gm-style .gm-style-iw-tc {
  top: -12px;
}

.gm-style .gm-style-iw-c {
  top: -12px;
}
