.ContentFiles {

  &__item {
    display: block;
    height: 72px;
    border-radius: 4px;
    border: solid 1px var(--clr-separator);
    background: var(--clr-white) $ic-file no-repeat 24px center;
    background-size: 24px 24px;
    padding: 16px 68px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    position: relative;
    margin-top: 8px;

    @include dark-theme {
      background-image: $ic-file--dark;
      background-color: rgba(255, 255, 255, 0.12);
      border-color: rgba(255, 255, 255, 0.24);
    }

    &:hover {
      transform: scale3d(1.02, 1.02, 1);
      box-shadow: rgba(0, 41, 69, 0.24) 0 4px 16px 0;
      text-decoration: none !important;
    }

    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: $ic-download no-repeat center;
      background-size: 24px 24px;
      position: absolute;
      right: 24px;
      top: 24px;
    }

    &--image {
      background-image: $ic-file-image;

      @include dark-theme {
        background-image: $ic-file-image--dark;
      }
    }

    &Name {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 24px;
      height: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--clr-text-primary);

      @include dark-theme {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    &Size {
      display: block;
      font-size: 0.75rem;
      line-height: 16px;
      height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--clr-text-secondary);
    }
  }
}