
body > noscript {
  font-family: -apple-system, '.SFNSDisplay-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  quotes: "“" "”";
  color: var(--clr-white);
  display: block;
  width: 100%;
  margin: 0;
  background: var(--clr-blue);
  padding: 24px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;

  a {
    color: var(--clr-white);
    font-weight: bold;
    text-decoration: underline;
  }
}
