.PickedPages {
  float: left;
  width: calc(100% - 354px);

  @media (max-width: $break-large) {
    width: calc(100% - 330px);
  }

  @media (max-width: $break-medium) {
    float: none;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0;
    margin: 0 -12px;

    @media (max-width: $break-medium) {
      margin-bottom: 16px;
    }

    @media (max-width: $break-small) {
      margin: 0 0 8px 0;
      padding: 16px 0 0 0;
    }
  }

  &__item {
    flex: 1 0 25%;
    display: block;
    height: 330px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    background: var(--clr-page) no-repeat center;
    background-size: cover;
    margin: 12px;
    transition: all .2s ease-in-out;

    &:nth-child(2) {
      flex: 1 0 35%;
    }

    @media (max-width: $break-medium) {
      display: none;
      flex: 1 0 25%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }

      &:nth-child(2),
      &:nth-child(3) {
        flex: 1 0 50%;
      }
    }

    @media (max-width: $break-small) {
      flex: 1 0 100% !important;
      margin: 0 0 16px 0;
      height: 240px;

      &:nth-child(4) {
        display: none;
      }
    }

    &:hover {
      transform: scale3d(1.02, 1.02, 1);
      box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.64);
    }
  }

  &__control {
    background-color: rgba(0, 0, 0, 0.48);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 24px 16px;
  }

  &__title {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--clr-white);
  }

  &__category {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1;
    color: var(--clr-text-light);
  }
}