* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
}

.Column {
  display: block;
  width: 50%;
  float: left;
}

.Layout {
  background: var(--clr-background);
}

.Container {
  display: block;
  width: 100%;
  max-width: 1440px;
  min-width: 320px;
  padding: 0 $container-padding-xlarge;
  margin: 0 auto;
  @include clear;

  @media (max-width: 1440px) {
    max-width: 1204px;
    padding: 0 $container-padding-large;
  }

  @media (max-width: $break-small) {
    padding: 0 $container-padding-small;
  }

  &--center {
    text-align: center;
  }

  &--animated {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
    animation: showContainer ease-in-out 300ms forwards;
    animation-delay: 200ms;
    will-change: transform;

    @media (max-width: $break-small) {
      opacity: 1;
      transform: none;
      animation: none;
    }
  }

  &--flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $break-medium) {
      flex-direction: column-reverse;
    }
  }

  &--waves {
    padding: 0;
    margin: 0 auto 102px auto;
    background: var(--clr-blue-light);
    position: relative;

    @media (max-width: 1440px) {
      padding: 0;
    }

    @media (max-width: $break-medium) {
      background: none;
      margin-bottom: 54px;
    }

    @media (max-width: $break-small) {
      margin-bottom: 38px;
    }

    &:after {
      position: absolute;
      bottom: -46px;
      left: $container-padding-xlarge;
      right: 0;
      content: "";
      display: block;
      height: 14px;
      background: $ic-wave-light repeat-x;
      background-size: 55px 14px;

      @include dark-theme {
        background-image: $ic-wave-light--dark;
      }

      @media (max-width: $break-large) {
        left: $container-padding-large;
        right: calc((100vw - 100%) / -2);
      }

      @media (max-width: $break-medium) {
        bottom: -14px;
        width: 100%;
        right: 0;
        left: 0;
      }
    }

    .Container__helper {
      position: absolute;
      left: $container-padding-xlarge;
      right: 0;
      bottom: -32px;
      height: 32px;
      background: var(--clr-blue-light);

      @media (max-width: $break-large) {
        left: $container-padding-large;
      }

      @media (max-width: $break-medium) {
        display: none;
      }
    }
  }
}

@keyframes showContainer {
  0% {
    transform: translate3d(0, 2%, 0);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
