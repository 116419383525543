.InstagramFeed {
  padding-top: 56px;

  p {
    padding-top: 24px;
    display: block;
    font-size: 0.875rem;
    line-height: 1.4285714286;
    color: var(--clr-text-primary);
  }

  &__embed {
    margin: 24px 0 120px;
    visibility: hidden;
    opacity: 0;

    &--ready {
      visibility: visible;
      opacity: 1;
    }
  }

  &__scroll {
    padding-top: 24px;
    display: block;
    overflow-x: auto;
    @include noscrollbar;
    padding-bottom: 24px;
    cursor: ew-resize;
  }

  &__content {
    @include clear;
  }

  &__item {
    display: block;
    float: left;
    width: 330px;
    margin-right: 24px;
    cursor: pointer;

    @media (max-width: $break-small) {
      margin-right: 16px;
      width: 280px;

      &:first-child {
        margin-left: 16px;
      }
    }

    &:hover .InstagramFeed__image {
      box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.64);
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    background: var(--clr-blue-light);
    border-radius: 4px;
    transition: all .2s ease-in-out;
    position: relative;
    will-change: box-shadow, transform;
    overflow: hidden;

    * {
      position: static !important;
      height: unset !important;
      width: unset !important;
    }
  }

  &__description {
    display: block;
    padding-top: 16px;
    color: var(--clr-text-tercial);
    font-size: 0.75rem;

    &Date {
      padding-top: 4px;
      display: block;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-tercial);
    }
  }

  &__author {
    display: block;
    padding-top: 16px;
    @include clear;

    &Name {
      display: block;
      font-size: 0.875rem;
      line-height: 1;
      color: var(--clr-blue);
      padding-top: 2px;
      font-weight: 600;
    }

    &Date {
      padding-top: 2px;
      display: block;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-tercial);
    }
  }

  &__avatar {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    float: left;
    margin-right: 8px;
    overflow: hidden;
    background: var(--clr-separator);

    @include dark-theme {
      background: var(--clr-navigation);
    }
  }
}
