@import "../variables";
@import "../mixins";


.PageContent,
.PlainContent {
  width: calc(100% - 212px - 25px);
  margin: -40px 0 88px 1px;
  background: var(--clr-page);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
  padding: 40px 48px 48px;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
  min-height: 320px;
  z-index: 1;

  @media (max-width: $break-medium) {
    width: 100%;
    margin: -88px 0 88px 0;
    padding: 16px 24px 24px;
  }

  @media (max-width: $break-small) {
    margin: 0 -16px 88px;
    width: calc(100% + 32px);
    padding: 8px 16px 16px;
  }

  p,
  div > strong {
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--clr-text-primary);
    padding-top: 8px;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--clr-separator);
    margin: 24px 0 0 0;
    padding: 0;
  }

  h2 {
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    color: var(--clr-text-primary);
    margin-top: 24px;

    @include dark-theme {
      color: var(--clr-white);
    }
  }

  h3 {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 24px;

    @include dark-theme {
      color: var(--clr-white);
    }
  }

  ul, ol {
    padding-top: 8px;
  }

  ul {
    list-style-position: outside;
    padding-left: 17px;

    li {
      @for $i from 1 through 4 {
        &.ql-indent-#{$i} {
          list-style-position: inside;
          padding-left: -24px + ($i * 24px);
        }
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol-counter;

    li {
      counter-increment: ol-counter;
      position: relative;
      padding-left: 24px;
      counter-reset: ol-counter-1 ol-counter-2 ol-counter-3 ol-counter-4;

      &:before {
        position: absolute;
        content: counter(ol-counter) ".";
        top: 0;
        left: 0;
        width: 24px;
      }

      &.ql-indent-1 {
        counter-reset: ol-counter-2 ol-counter-3 ol-counter-4;
      }

      &.ql-indent-2 {
        counter-reset: ol-counter-3 ol-counter-4;
      }

      &.ql-indent-3 {
        counter-reset: ol-counter-4;
      }

      &.ql-indent-4 {
        counter-reset: none;
      }

      @for $i from 1 through 4 {

        &.ql-indent-#{$i} {
          counter-increment: ol-counter-#{$i};
          padding-left: 24px + ($i * 24px);

          &:before {
            left: $i * 24px;
            content: counter(ol-counter-#{$i}) ".";
          }
        }
      }
    }
  }

  .Table__scroll {
    margin-right: -48px;
    overflow: auto;
    @include scrollbar(auto, 4px, var(--clr-blue), var(--clr-blue-darker));
  }

  @media (max-width: $break-medium) {

    .Table__scroll {
      margin-right: -24px;
      overflow: auto;
      @include scrollbar(auto, 4px, var(--clr-blue), var(--clr-blue-darker));
    }
  }

  @media (max-width: $break-small) {

    .Table__scroll {
      margin-right: -16px;
    }
  }

  table {
    width: 100%;
    text-align: left;
    line-height: 24px;
    border-collapse: collapse;
    margin-top: 16px;

    td, th {
      padding: 4px 16px;
      white-space: nowrap;

      p {
        padding: 0;
      }
    }

    th {
      background-color: rgba(42, 51, 55, 0.08);
    }

    tr:nth-child(2n + 1) td {
      background-color: rgba(42, 51, 55, 0.04);

      @include dark-theme {
        background-color: rgba(255, 255, 255, 0.04);
      }
    }

    tr:first-child td {
      background-color: rgba(42, 51, 55, 0.08);
      font-weight: bold;

      @include dark-theme {
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }

  a {
    color: var(--clr-blue);

    &:hover {
      text-decoration: underline;
    }
  }

  del,
  s {
    text-decoration: line-through;
    color: rgba(42, 51, 55, 0.64);
    cursor: not-allowed;
  }

  iframe {
    width: 100%;
    height: 463px;
    margin-top: 16px;

    @media (max-width: $break-small) {
      height: 240px;
    }
  }

  &__footer {
    display: block;
    width: 100%;
    height: 88px;
    position: absolute;
    bottom: -88px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.08);
    @include clear;

    @include dark-theme {
      background-color: #1d1d1d;
    }

    &Page {
      display: block;
      float: left;
      width: 50%;
      cursor: pointer;
      padding: 24px 48px 0 48px;
      position: relative;

      @media (max-width: $break-small) {

        &--previous {
          padding-left: 24px;
          padding-right: 8px;
        }

        &--next {
          padding-left: 8px;
          padding-right: 24px;
        }
      }

      &:hover {
        text-decoration: none !important;

        .PageContent__footerPageTitle {
          text-decoration: underline !important;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 32px;
      }

      &--previous:after {
        background: $ic-page-previous no-repeat center;
        background-size: 24px 24px;
        left: 12px;

        @media (max-width: $break-small) {
          left: 0;
        }
      }

      &--next:after {
        background: $ic-page-next no-repeat center;
        background-size: 24px 24px;
        right: 12px;

        @media (max-width: $break-small) {
          right: 0;
        }
      }

      &--next {
        float: right;
      }

      &Title {
        display: block;
        font-size: 1rem;
        font-weight: bold;
        line-height: 24px;
        color: var(--clr-blue);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &Subtitle {
        display: block;
        font-size: 0.875rem;
        line-height: 20px;
        color: var(--clr-text-primary);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include dark-theme {
          color: var(--clr-text-tercial);
        }
      }

      &--next {
        text-align: right;
      }
    }
  }

  &__embed {
    margin: -72px -64px 0;
    height: 160px;

    @media (max-width: $break-medium) {
      margin: -48px -40px 0;
    }

    @media (max-width: $break-small) {
      margin: -40px -32px 0;
    }
  }
}
