.Footer {
  background: var(--clr-blue);
  padding: 42px 0 0;
  position: relative;
  margin-top: 46px;

  @include dark-theme {
    background: var(--clr-blue-light);
  }

  .Container {
    position: relative;
    min-height: 320px;
  }

  .Column {

    &:first-child {
      width: 40%;

      @media (max-width: $break-medium) {
        width: 100%;
      }
    }

    &:last-child {
      width: 60%;

      @media (max-width: $break-medium) {
        width: 100%;
      }
    }
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 14px;
    background: $ic-wave-up repeat-x;
    background-size: 55px 14px;
    position: absolute;
    top: -14px;
    left: 0;

    @include dark-theme {
      background-image: $ic-wave-up--dark;
    }
  }

  &__brand {
    display: block;
    width: 218px;
    height: 48px;
    background: $ic-brand-inverse no-repeat center;
    background-size: 218px 48px;
    text-indent: -9999px;

    @media (max-width: $break-medium) {
      margin: auto;
    }

    @media (max-width: $break-small) {
      margin: 0;
    }
  }

  &__info {
    padding: 24px 0;
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: rgb(179, 220, 248);
    line-height: 1.5714285714;

    &Link {
      color: var(--clr-white);

      &:hover {
        text-decoration: underline;
      }
    }

    &Social {
      margin-top: 16px;

      &Link {
        display: inline-block;
        color: var(--clr-white);
        font-weight: bold;
        padding-left: 32px;
        min-height: 24px;
        line-height: 24px;
        text-indent: -9999px;

        &--instagram {
          background: url("/instagram.png") no-repeat center left;
          background-size: 24px 24px;

          @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
              background-image: url("/instagram@2x.png");
          }
        }

        &--youtube {
          background: url("/youtube.svg") no-repeat center left;
          background-size: 24px 24px;
        }

        &--facebook {
          background: $ic-facebook no-repeat center left;
          background-size: 24px 24px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (max-width: $break-medium) {
      text-align: center;
    }

    @media (max-width: $break-small) {
      padding-bottom: 0;
    }

    @media (max-width: $break-small) {
      text-align: left;
    }
  }

  &__author {
    display: block;
    padding: 12px 0 12px 64px;
    height: 48px;
    background: $ic-author no-repeat left;
    background-size: 48px 48px;
    font-size: 0.875rem;
    font-weight: 500;
    color: rgb(179, 220, 248);
    position: absolute;
    bottom: 0;
    left: $container-padding-xlarge;
    line-height: 24px;

    strong {
      color: var(--clr-white);
      font-weight: 400;
    }

    &:hover strong {
      text-decoration: underline;
    }

    @media (max-width: $break-large) {
      left: $container-padding-large;
    }

    @media (max-width: $break-small) {
      left: $container-padding-small;
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-right: -4px;

    @media (max-width: $break-medium) {
      padding-bottom: 40px;
    }

    @media (max-width: $break-small) {
      margin-right: 0;
      padding-bottom: 128px;
    }
  }

  &__section {
    display: block;
    flex: 1 0 20%;
    padding: 34px 4px 40px;
    width: 20%;

    @media (max-width: $break-medium) {
      padding-bottom: 72px;
    }

    @media (max-width: $break-small) {
      flex: 1 0 100%;
      width: 100%;
      padding: 40px 0 0 0;
      @include clear;
    }

    &Title {
      display: block;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.5px;
      color: rgb(179, 220, 248);
      text-transform: uppercase;
      padding-bottom: 24px;

      @media (max-width: $break-small) {
        padding-bottom: 8px;
      }

      @include dark-theme {
        color: var(--clr-blue);
      }
    }

    &Link {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--clr-white);
      line-height: 1.5714285714;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $break-small) {
        float: left;
        margin-right: 24px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
