
.NewsContent.PageContent {
  max-width: 968px;
  width: 100%;
  min-height: 400px;
  margin: 0 auto 256px;
  padding-bottom: 48px;

  @media (max-width: $break-small) {
    margin: -50px -16px 156px;
    width: calc(100% + 32px);
    padding-top: 24px;
  }
}

.NewsContent {

  .Flag {
    float: left;
  }

  &__header {
    @include clear;
  }

  &__helper {
    display: block;
    width: 100%;
    max-height: 340px;
    min-height: 240px;
    height: 50%;
    background: var(--clr-blue-light);
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 14px;
      background: $ic-wave-light repeat-x;
      background-size: 55px 14px;
      position: absolute;
      bottom: -14px;
      left: 0;

      @include dark-theme {
        background-image: $ic-wave-light--dark;
      }
    }

    @media (max-width: $break-small) {
      display: none;
    }
  }

  &__author {
    display: block;
    font-size: 0.875rem;
    line-height: 2.2857142857;
    text-align: right;
    color: var(--clr-text-secondary);
    float: right;
  }
}