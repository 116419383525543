
.GuestCard {
  margin: 32px 0 56px;

  &__box {
    display: grid;
    grid-template-columns: 1fr 354px;
    background: var(--clr-blue-light);
    border-radius: 0 4px 4px 4px;
    overflow: hidden;
    color: var(--clr-text-primary);

    @media (max-width: 950px) {
      grid-template-columns: 1fr 268px;
    }

    @media (max-width: $break-small) {
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    padding: 24px;

    @media (max-width: $break-small) {
      padding: 16px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__ticket {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 28px;
    background: linear-gradient(90deg, rgba(0, 108, 182, 0) 0%, #006CB6 100%);
    position: relative;
    overflow: hidden;

    @media (max-width: $break-small) {
      background: linear-gradient(180deg, rgba(0, 108, 182, 0) 0%, rgba(0, 108, 182, 0.72) 96.57%);
      padding: 16px;
      justify-content: center;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      border-left: 4px dotted var(--clr-background);
      z-index: 1;

      @media (max-width: $break-small) {
        bottom: auto;
        right: 0;
        width: auto;
        border-left: none;
        border-top: 4px dotted var(--clr-background);
      }
    }
  }

  &__discount {
    position: absolute;
    font-size: 160px;
    line-height: 1;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: var(--clr-white);
    opacity: .32;
    user-select: none;
    pointer-events: none;

    @media (max-width: 950px) {
      display: none;
    }
  }

  &__button {
    appearance: none;
    border: none;
    background: var(--clr-red);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.32), inset 0 1px 1px rgba(255, 255, 255, 0.48);
    border-radius: 8px;
    position: relative;
    z-index: 1;
    padding: 8px 8px 8px 16px;
    cursor: pointer;
    color: var(--clr-white);
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
