
.ContentRouteValidator {
  background-color: #f4f4f4;
  margin-top: 16px;
  padding: 24px;
  position: relative;
  @include clear;

  @include dark-theme {
    border: solid 1px rgba(255, 255, 255, 0.24);
    background-color: rgba(255, 255, 255, 0.12);
  }

  &__anchor {
    position: absolute;
    top: -140px;
    left: 0;
  }

  &__label {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #2a3337;
    padding-bottom: 8px;

    @include dark-theme {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &__select {
    display: block;
    height: 48px;
    width: 50%;
    float: left;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    padding: 12px 16px;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #2a3337;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: var(--clr-white) $ic-chevron-red-down no-repeat right 12px center;
    background-size: 24px 24px;

    @include dark-theme {
      border-color: rgba(255, 255, 255, 0.16);
      background-color: rgba(0, 0, 0, 0.24);
      color: rgba(255, 255, 255, 0.8);
    }

    &:focus {
      border-color: var(--clr-blue);
      border-width: 2px;
      padding: 11px 15px;
      background-position: right 11px center;
    }

    @media (max-width: 1130px) {
      width: 100%;
      float: none;
    }

    @media (max-width: 980px) {
      width: 50%;
      float: left;
    }

    @media (max-width: 840px) {
      width: 100%;
      float: none;
    }
  }

  &__help {
    display: block;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4285714286;
    letter-spacing: normal;
    color: var(--clr-text-tercial);
    float: left;
    width: 50%;
    padding: 4px 0 0 24px;

    @media (max-width: 1130px) {
      width: 100%;
      float: none;
      padding-left: 0;
    }

    @media (max-width: 980px) {
      width: 50%;
      float: left;
      padding-left: 24px;
    }

    @media (max-width: 840px) {
      width: 100%;
      float: none;
      padding-left: 0;
    }
  }

  &__number {
    padding-top: 24px;
    clear: both;
    @include clear;

    &Item {
      float: left;
      width: 56px;
      margin: 4px 8px 4px 0;
      position: relative;
      padding-top: 16px;

      @media (max-width: 600px) {
        width: calc(25% - 8px);
      }

      &Title {
        background: var(--clr-blue-light);
        color: var(--clr-blue);
        text-align: center;
        border-radius: 4px 4px 0 0;
        font-size: 0.625rem;
        line-height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 16px;

        @include dark-theme {
          color: var(--clr-white);
          background-color: rgba(255, 255, 255, 0.16);
        }
      }
    }
  }

  &__input {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: #2a3337;
    border-radius: 0 0 4px 4px;
    border: solid 1px #ebebeb;
    border-top: none;
    background-color: var(--clr-white);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 10px 0;

    @include dark-theme {
      border-color: rgba(255, 255, 255, 0.16);
      background-color: rgba(0, 0, 0, 0.24);
      color: rgba(255, 255, 255, 0.8);
    }

    &:focus {
      border-color: var(--clr-blue);
      border-width: 2px;
      padding: 10px 0 9px 0;
    }

    &:focus + .ContentRouteValidator__numberItemTitle {
      background: var(--clr-blue);
      color: var(--clr-white);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__button {
    display: inline-block;
    height: 48px;
    border: none;
    border-radius: 4px;
    background: var(--clr-blue);
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    padding: 15px 24px 17px;
    color: var(--clr-white);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    margin-top: 24px;
    cursor: pointer;
    transition: all ease-in 100ms;

    &[disabled] {
      background: var(--clr-blue-light);
      color: var(--clr-blue-lighter);
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
    }

    &:active {
      background: var(--clr-blue-dark);
    }

    &--alert {
      background: var(--clr-white);
      color: rgba(0, 0, 0, 0.8);

      &:active {
        background: var(--clr-light);
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  &__message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: var(--clr-white);
    padding: 32px;

    &Content {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      padding: 32px;
    }

    &--success {
      background-color: #71b600;

      @include dark-theme {
        background-color: #50672a;
        border-color: #50672a;
      }
    }

    &--error {
      background-color: var(--clr-red);
    }

    span {
      display: block;
    }
  }
}
