
@keyframes fadeHighlight {
  0% {
    background: var(--clr-highlight);
    border-color: var(--clr-highlight-dark);
  }
  100% {
    background: var(--clr-page);
    border-color: var(--clr-page);
  }
}

.List {
  margin: -40px auto 168px;
  width: 100%;
  max-width: 920px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
  background-color: var(--clr-page);
  position: relative;
  padding: 0 48px 48px;
  min-height: 320px;

  @media (max-width: $break-medium) {
    margin: -88px auto 0;
    padding: 0 24px 24px;
  }

  @media (max-width: $break-small) {
    margin: 0 -16px;
    width: calc(100% + 32px);
    padding: 8px 16px 16px;
    box-shadow: none;
    background: none;
  }

  .Flag {
    margin-bottom: 16px;
    margin-top: 40px;

    @media (max-width: $break-medium) {
      margin-top: 24px;
    }
  }

  &__section {
    padding-top: 40px;
    position: relative;

    .Flag {
      margin-top: 0;
    }

    &--highlighted &Content {
      padding: 14px;
      border-radius: 4px;
      margin: -16px;
      border: 2px solid var(--clr-page);
      transition: opacity .2s ease-in-out;

      animation-name: fadeHighlight;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }

  &__anchor {
    position: absolute;
    top: -100px;
    left: 0;
  }

  &__item {
    display: block;
    padding: 8px 24px;
    cursor: pointer;

    @media (max-width: $break-small) {
      padding: 8px 0;
    }

    &:hover h4 {
      text-decoration: underline;
    }

    small {
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-tercial);
      text-transform: uppercase;
    }

    h4 {
      display: block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5;
      color: var(--clr-blue);
    }

    p {
      display: block;
      font-size: 0.875rem;
      line-height: 1.4285714286;
      color: var(--clr-text-primary);
    }
  }

  &__button {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--clr-blue);
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    border-top: 1px solid var(--clr-separator);
    padding-top: 40px;
    background: none;
    border-radius: 0;

    &:hover {
      text-decoration: underline;
    }

    &[disabled] {
      opacity: .4;

      &:hover {
        text-decoration: none;
      }
    }
  }
}