.ContentImage {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
  margin: 24px 0;
  padding-top: 4px;

  &--two {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 780px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: $break-small) {
    grid-gap: 16px;
  }

  &--single {
    display: block;
    padding: 0;
    margin: 40px 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background: var(--clr-blue-light);

    @media(max-width: $break-medium) {
      margin: 24px 0;
    }

    @media (max-width: $break-small) {
      width: calc(100% + 32px);
      margin: 16px -16px;
    }

    img {
      display: block;
      width: 100%;
    }

    &:hover {
      box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.64);

      @media(max-width: $break-medium) {
        transform: none;
      }
    }
  }

  &__item {
    display: block;
    width: 100%;
    object-position: center;
    object-fit: cover;
    background-color: var(--clr-blue-light);
    cursor: pointer;
    transition: all .2s ease-in-out;
    aspect-ratio: 4 / 3;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @supports not (aspect-ratio: auto) {
      padding-top: 80%;
      height: 0;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:hover {
      box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.64);
    }
  }
}