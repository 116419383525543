
@keyframes moveBackground {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate3d(-3%, -3%, 0);
    opacity: 0;
  }
}

@keyframes fadeTitle {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInTitle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Recommend {
  height: 440px;
  background: rgb(0, 0, 0);
  max-width: 944px;
  position: relative;

  @media (max-width: 1440px) {
    width: calc((100% - 354px) + ((100vw - 100%) / 2));
    max-width: none;
    left: calc(((100vw - 100%) / -2));
  }

  @media (max-width: $break-medium) {
    width: 100%;
    left: 0;
  }

  @media (max-width: $break-small) {
    height: 240px;
  }

  &__title {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    color: var(--clr-white);
    background: var(--clr-red);
    text-transform: uppercase;
    padding: 7px 24px 5px;
    position: absolute;
    top: -32px;
    left: $container-padding-xlarge;

    @media (max-width: 1440px) {
      left: $container-padding-large;
    }

    @media (max-width: $break-small) {
      left: 0;
      font-size: 0.75rem;
      height: 26px;
      padding: 4px $container-padding-small 2px;
      top: -26px;
    }
  }

  &__slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include dark-theme {
      background: var(--clr-page);
    }

    &Item {
      position: absolute;
      top: 0;
      left: 0;
      width: 103%;
      height: 103%;
      background: no-repeat center;
      background-size: cover;
      opacity: 0;

      &--animate {
        animation-name: moveBackground;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
      }

      &--paused {
        animation-play-state: paused;
      }

      &--visible {
        opacity: 1;
      }

      &--video {
        width: 100%;
        height: 100%;
        animation: none;
        opacity: 1;

        iframe {
          position: absolute;
          pointer-events: none;
          left: 0;
          width: 100%;
          height: 300%;
          top: -100%;

          @media (max-width: 1140px) {
            width: 300%;
            height: 100%;
            left: -100%;
            top: 0;
          }

          @media (max-width: $break-medium) {
            width: 100%;
            height: 300%;
            top: -100%;
            left: 0;
          }

          @media (max-width: 786px) {
            width: 300%;
            height: 100%;
            left: -100%;
            top: 0;
          }

          @media (max-width: $break-small) {
            width: 100%;
            height: 300%;
            top: -100%;
            left: 0;
          }

          @media (max-width: 430px) {
            width: 300%;
            height: 100%;
            left: -100%;
            top: 0;
          }
        }
      }
    }
  }

  &__control {
    position: absolute;
    bottom: 0;
    right: 0;
    left: $container-padding-xlarge;
    height: 126px;
    background-color: rgba(0, 0, 0, 0.4);
    max-width: 802px;

    @media (max-width: 1440px) {
      left: $container-padding-large;
      max-width: none;
      width: calc(100% - 354px - 24px);
    }

    @media (max-width: $break-medium) {
      width: 100%;
      left: 0;
      bottom: auto;
      top: 314px;
    }

    @media (max-width: $break-small) {
      height: 88px;
      top: 152px
    }

    h2 {
      display: block;
      text-shadow: 0 8px 24px rgba(0, 0, 0, 0.64);
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: -0.5px;
      color: var(--clr-white);
      padding: 24px;
      width: calc(100% - 252px);
      line-height: 78px;
      max-height: 102px;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0;

      &.animate {
        animation-name: fadeTitle;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
      }

      &.paused {
        animation-play-state: paused;
      }

      &.visible {
        opacity: 1;
      }

      &.video {
        animation: none;
        opacity: 1;
      }

      @media (max-width: $break-small) {
        padding: 16px;
        font-size: 1.375rem;
        font-weight: bold;
        line-height: 56px;
        letter-spacing: -0.3px;
        max-height: 70px;
        width: calc(100% - 88px);
      }

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 39px;

        @media (max-width: $break-small) {
          line-height: 28px;
        }
      }
    }

    &Button {
      display: block;
      width: 126px;
      height: 126px;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      background: rgba(0, 0, 0, 0.4) no-repeat center;
      background-size: 48px 48px;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      text-indent: -9999px;
      transition: opacity .2s ease-in-out;

      &:active {
        opacity: .7;
      }

      @media (max-width: $break-small) {
        width: 44px;
        height: 88px;
        background-size: 24px 24px;
      }

      &--right {
        background-image: $ic-slide-right;
        background-color: rgba(0, 0, 0, 0.6);
        right: 0;
      }

      &--left {
        background-image: $ic-slide-left;
        right: 126px;

        @media (max-width: $break-small) {
          right: 44px;
        }
      }
    }
  }
}