/*!--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
#outdated {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1500;
  background-color: #f25648;
  color: #ffffff;

  h6 {
    font-size: 1.5625rem;
    line-height: 1;
    margin: 30px 0 10px;
  }

  p {
    font-size: 0.75rem;
    line-height: 1;
    margin: 0;
  }

  #btnUpdateBrowser {
    display: block;
    position: relative;
    padding: 10px 20px;
    margin: 30px auto 0;
    width: 230px; /*need for IE*/
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #ffffff;
    cursor: pointer;

    &:hover {
      color: #f25648;
      background-color: #ffffff;
    }
  }

  .last {
    position: absolute;
    top: 10px;
    right: 25px;
    width: 20px;
    height: 20px;

    &[dir='rtl'] {
      right: auto !important;
      left: 25px !important;
    }
  }

  #btnCloseUpdateBrowser {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    font-size: 2.25rem;
    line-height: 1;
  }

  * html & {
    position: absolute;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  #outdated {
    opacity: 1 !important;
    display: block !important;
  }
}