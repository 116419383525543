

.NewsList {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -32px;
  width: 330px + $container-padding-xlarge;
  z-index: 1;
  background: var(--clr-blue-light);
  padding-bottom: 16px;

  @media (max-width: $break-large) {
    width: calc(330px + (100vw - 100%) / 2);
    right: calc((100vw - 100%) / -2);
  }

  @media (max-width: $break-medium) {
    position: relative;
    bottom: auto;
    width: 100%;
    margin-top: 72px;
    padding-bottom: 0;
  }

  @media (max-width: $break-small) {
    margin-top: 50px;
  }

  &__title {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    color: var(--clr-white);
    background: var(--clr-red);
    text-transform: uppercase;
    padding: 7px 24px 5px;
    position: absolute;
    height: 32px;
    top: -32px;
    left: -24px;

    @media (max-width: $break-medium) {
      left: $container-padding-large;
    }

    @media (max-width: $break-small) {
      left: 0;
      font-size: 0.75rem;
      height: 26px;
      padding: 4px $container-padding-small 2px;
      top: -26px;
    }
  }

  &__scroll {
    padding: 8px $container-padding-xlarge 32px 0;
    display: block;
    overflow: auto;
    height: 100%;
    @include scrollbar(4px, auto, var(--clr-blue), var(--clr-blue-darker));

    @media (max-width: $break-large) {
      padding-right: $container-padding-large;
    }

    @media (max-width: $break-medium) {
      height: auto;
      overflow: visible;
      padding: 8px $container-padding-large 18px;
    }

    @media (max-width: $break-small) {
      padding: 0 $container-padding-small 18px;
    }
  }

  &__shadow {
    display: block;
    position: relative;
    height: 100%;

    @media (max-width: $break-medium) {
      position: static;
      height: auto;
    }

    &:before {
      content: "";
      display: block;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 4px;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dbe7f3+0,dbe7f3+100&1+0,0+100 */
      background: -moz-linear-gradient(top, rgb(215, 229, 242) 0%, rgba(215, 229, 242, 0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(215, 229, 242, 1) 0%, rgba(215, 229, 242, 0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(215, 229, 242, 1) 0%, rgba(215, 229, 242, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dbe7f3', endColorstr='#00dbe7f3', GradientType=0); /* IE6-9 */

      @include dark-theme {
        background: -moz-linear-gradient(top, rgb(0, 46, 78) 0%, rgba(0, 46, 78, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 46, 78, 1) 0%, rgba(0, 46, 78, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 46, 78, 1) 0%, rgba(0, 46, 78, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002e4e', endColorstr='#00002e4e', GradientType=0); /* IE6-9 */
      }

      @media (max-width: $break-medium) {
        display: none;
      }
    }

    &:after {
      content: "";
      display: block;
      height: 32px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 4px;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dbe7f3+0,dbe7f3+100&0+0,1+100 */
      background: -moz-linear-gradient(top, rgba(215, 229, 242, 0) 0%, rgba(215, 229, 242, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(215, 229, 242, 0) 0%, rgba(215, 229, 242, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(215, 229, 242, 0) 0%, rgba(215, 229, 242, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00dbe7f3', endColorstr='#dbe7f3', GradientType=0); /* IE6-9 */

      @include dark-theme {
        background: -moz-linear-gradient(top, rgba(0, 46, 78, 0) 0%, rgba(0, 46, 78, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 46, 78, 0) 0%, rgba(0, 46, 78, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 46, 78, 0) 0%, rgba(0, 46, 78, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00002e4e', endColorstr='#002e4e', GradientType=0); /* IE6-9 */
      }

      @media (max-width: $break-medium) {
        display: none;
      }
    }
  }

  &__item {
    display: block;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
    max-width: 330px;

    @media (max-width: $break-large) {
      max-width: 306px;
    }

    @media (max-width: $break-medium) {
      max-width: none;
    }

    @media (max-width: $break-small) {
      display: none;

      &:first-child {
        display: block;
      }
    }

    &:hover h4 {
      text-decoration: underline;
    }

    &--last {
      border-bottom: none;

      @media (max-width: $break-medium) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }
    }

    small {
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1;
      color: var(--clr-text-tercial);
      text-transform: uppercase;
    }

    h4 {
      display: block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5;
      color: var(--clr-blue);
    }

    p {
      display: block;
      font-size: 0.875rem;
      line-height: 1.4285714286;
      color: var(--clr-text-primary);

      @include dark-theme {
        color: var(--clr-white);
      }
    }
  }

  &__linkMore {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--clr-blue);

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: $break-medium) {
      margin-top: 16px;
      width: 100%;
      text-align: center;
    }
  }
}