.Error {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--clr-text-primary);
  padding-bottom: 128px;

  h1 {
    font-size: 7.5rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: var(--clr-text-primary);
    padding-bottom: 32px;

    @media (max-width: $break-medium) {
      font-size: 5rem;
      line-height: 1.1;
    }

    @media (max-width: $break-small) {
      font-size: 3rem;
      line-height: 1.1666666667;
    }
  }

  p {
    padding-top: 24px;
  }

  a {
    color: var(--clr-blue);

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding: 16px 0 0 24px;

    li {
      list-style-position: outside;
    }
  }
}