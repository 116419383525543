$navigation-height: 88px;
$navigation-menu-height: $navigation-height - 32px;
$navigation-content-width: calc(100% - 212px - 24px);

.Navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $navigation-height;
  background: var(--clr-navigation);
  box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.16);
  z-index: 2;

  @include dark-theme {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .24);
  }

  &--small {
    height: 102px;

    .Container {
      position: relative;
    }

    .Navigation__menu {
      visibility: hidden;
      height: 0;

      &Button {
        display: block;
      }
    }

    .Navigation__brand {
      width: 162px;
      height: 68px;
      background-size: 162px 36px;
      margin: 0;
    }

    .Navigation__content {
      width: calc(100% - 162px - 24px);
      height: 68px;
      position: static;
      border-left: none;

      @media (max-width: $break-small) {
        width: calc(100% - 162px);
        margin-left: 0;
      }
    }

    .Navigation__language {
      position: relative;
      float: right;
      margin-top: 18px;

      &Toggle {
        border-radius: 4px;
        border: solid 1px var(--clr-separator);
        text-indent: -9999px;

        &:after {
          top: 9px;
        }
      }

      &Dropdown {
        right: -36px;
        top: 36px;
      }
    }

    .Navigation__breadcrumb {
      position: absolute;
      bottom: -32px;
      left: 0;
      border-bottom: none;
      border-top: 1px solid var(--clr-separator);
      padding: 9px 24px 8px;

      @media (max-width: $break-small) {
        padding: 9px 16px 8px;
      }

      &Helper {
        transform: none;
        top: auto;
        bottom: 33px;
        left: 0;
        width: 100%;
        height: 1px;
      }
    }

    .Navigation__search {
      display: none;

      &Field {
        display: none;
      }

      &Results {
        display: none;
      }
    }
  }

  &__helper {
    display: block;
    height: $navigation-height;
    margin-bottom: 88px;

    &--small {
      height: 102px;
      margin-bottom: 50px;
    }
  }

  &__brand {
    display: block;
    width: 212px;
    height: $navigation-height - 32px;
    background: $ic-brand no-repeat center;
    background-size: 212px 47px;
    text-indent: -9999px;
    float: left;
    margin: 16px 0;
    border-radius: 4px;
    transition: transform ease-in-out 200ms;

    @include dark-theme {
      background-image: $ic-brand--dark;
    }

    &:active {
      transform: scale3d(0.96, 0.96, 1);
    }

    @media (max-width: $break-medium) {
      width: 162px;
      background-size: 162px 36px;
    }
  }

  &__content {
    position: relative;
    display: block;
    float: left;
    height: $navigation-height;
    border-left: 1px solid var(--clr-separator);
    width: $navigation-content-width;
    margin-left: 24px;
    background: var(--clr-navigation);

    @media (max-width: $break-medium) {
      width: calc(100% - 162px - 24px);
    }
  }

  &__breadcrumb {
    display: block;
    font-size: 0.8125rem;
    line-height: 14px;
    color: var(--clr-text-secondary);
    height: 32px;
    padding: 9px 24px 8px;
    width: 100%;
    border-bottom: 1px solid var(--clr-separator);
    position: relative;
    z-index: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include dark-theme {
      color: var(--clr-white);
    }

    h1 {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    @media (max-width: $break-medium) {
      padding: 8px 16px;
    }

    &Helper {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 32px;
      border-bottom: 1px solid var(--clr-separator);
      z-index: 1;
    }

    &Item {
      display: inline-block;
      font-size: 0.8125rem;
      line-height: 15px;
      color: var(--clr-blue);
      position: relative;
      padding-right: 14px;
      margin-left: 2px;

      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background: $ic-breadcrumb no-repeat center;
        background-size: 12px 12px;
        position: absolute;
        top: 2px;
        right: 0;

        @include dark-theme {
          background-image: $ic-breadcrumb--dark;
        }
      }

      &--noLink {
        color: var(--clr-text-secondary);

        @include dark-theme {
          color: var(--clr-white);
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        cursor: text;
        color: var(--clr-text-secondary);

        @include dark-theme {
          color: var(--clr-white);
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &__menu {
    display: block;
    float: left;
    width: calc(100% - 64px);
    height: $navigation-menu-height;
    z-index: 1;
    position: relative;
    @include clear();

    @media (max-width: $break-medium) {
      width: calc(100% - 48px);
    }

    & > div {
      float: left;
      @include clear();
    }

    &Button {
      display: none;
      width: 48px;
      height: 48px;
      background: $ic-menu no-repeat center;
      background-size: 24px 24px;
      text-indent: -9999px;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      cursor: pointer;
      float: right;
      margin: 10px -8px 10px 16px;
      z-index: 2;
      position: relative;

      @include dark-theme {
        background-image: $ic-menu--dark;
      }
    }

    &Dropdown {
      display: block;
      float: left;

      &:first-child .Navigation__menuItem--dropdown {
        border-left: none;
      }

      .Navigation__menuItem--dropdown {
        float: none;
        cursor: pointer;
        z-index: 1;
        position: relative;
      }

      &--opened {

        &:last-child .Navigation__menuItem--dropdown {
          border-right: 1px solid var(--clr-separator);
        }

        .Navigation__menuItem--dropdown {
          height: $navigation-menu-height + 1px;
          background: var(--clr-navigation);
          color: var(--clr-red);

          &:after {
            background: $ic-chevron-up no-repeat center;
            background-size: 12px 12px;
          }
        }

        .Navigation__menuDropdownContainer {
          display: block;
        }
      }

      &Container {
        display: none;
        position: absolute;
        top: -32px;
        left: -1px;
        background: var(--clr-navigation);
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
        width: calc((100% + (100vw - (100% + 212px)) / 2) + 24px);
        padding: ($navigation-height + 24px) 0 0;

        &:before {
          content: "";
          display: block;
          width: 100%;
          position: absolute;
          height: $navigation-height;
          top: 0;
          left: 0;
          border-bottom: 1px solid var(--clr-separator);
        }
      }

      &Shadow {
        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 4px;
          left: 0;
          width: 24px;
          z-index: 1;

          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */

          @include dark-theme {
            background: -moz-linear-gradient(left, rgba(41, 41, 41, 1) 0%, rgba(41, 41, 41, 0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(41, 41, 41, 1) 0%, rgba(41, 41, 41, 0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(41, 41, 41, 1) 0%, rgba(41, 41, 41, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#292929', endColorstr='#00292929', GradientType=1); /* IE6-9 */
          }
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 4px;
          right: 0;
          width: 24px;
          z-index: 1;

          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */

          @include dark-theme {
            background: -moz-linear-gradient(left, rgba(41, 41, 41, 0) 0%, rgba(41, 41, 41, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(41, 41, 41, 0) 0%, rgba(41, 41, 41, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(41, 41, 41, 0) 0%, rgba(41, 41, 41, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00292929', endColorstr='#292929', GradientType=1); /* IE6-9 */
          }
        }
      }

      &Scroll {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 24px 24px;
        overflow-x: auto;
        overflow-y: visible;
        position: relative;
        @include scrollbar(auto, 4px, var(--clr-blue), var(--clr-blue-darker));

        &Content {
          width: 10000px;
          padding-right: $container-padding-large;
          padding-bottom: 24px;
        }
      }
    }

    &Item {
      display: block;
      float: left;
      height: 100%;
      font-size: 0.875rem;
      line-height: 15px;
      color: var(--clr-blue);
      text-transform: uppercase;
      font-weight: 500;
      padding: 21px 24px 20px;
      border-left: 1px solid var(--clr-separator);

      @media (max-width: $break-medium) {
        padding: 22px 16px 20px;

        &:last-child {
          border-right: 1px solid var(--clr-separator);
        }
      }

      &:focus {
        background-color: var(--clr-blue-light);
      }

      &--dropdown,
      &--external {
        position: relative;
        padding-right: 32px;

        @media (max-width: $break-medium) {
          padding-right: 28px;
        }

        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background: $ic-chevron-down no-repeat center;
          background-size: 12px 12px;
          position: absolute;
          top: ($navigation-menu-height * 0.5) - 6px;
          right: 16px;

          @include dark-theme {
            background-image: $ic-chevron-down--dark;
          }

          @media (max-width: $break-medium) {
            right: 12px;
          }
        }
      }

      &--external {
        padding-right: 36px;

        @media (max-width: $break-medium) {
          padding-right: 28px;
        }

        &:after {
          background: $ic-link no-repeat center;
          background-size: 16px 16px;
          top: ($navigation-menu-height * 0.5) - 5px;
        }

        &:focus {
          background: none;
        }
      }
    }

    &Section {
      display: block;
      float: left;
      width: 196px;
      padding-right: 64px;

      &:last-child {
        width: 176px;
        padding-right: 0;
      }

      &Title {
        display: block;
        font-size: 0.875rem;
        line-height: 1;
        font-weight: bold;
        color: var(--clr-text-primary);
        padding-bottom: 14px;
      }

      &Link {
        display: block;
        font-size: 0.875rem;
        line-height: 1.5714285714;
        font-weight: 500;
        color: var(--clr-blue);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__language {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;

    &--opened {

      .Navigation__languageDropdown {
        display: block;
      }
    }

    &Toggle {
      display: block;
      height: 32px;
      line-height: 32px;
      color: var(--clr-blue);
      padding: 0 22px 0 32px;
      margin: 0 -8px 0 0;
      position: relative;
      font-size: 0.8125rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      cursor: pointer;
      border-radius: 4px;

      &--cz {
        background: $ic-lang-cs no-repeat 8px center;
        background-size: 17px 12px;
      }

      &--de {
        background: $ic-lang-de no-repeat 8px center;
        background-size: 17px 12px;
      }

      &--en {
        background: $ic-lang-en no-repeat 8px center;
        background-size: 17px 12px;
      }

      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background: $ic-chevron-down no-repeat center;
        background-size: 12px 12px;
        position: absolute;
        top: 10px;
        right: 8px;

        @include dark-theme {
          background-image: $ic-chevron-down--dark;
        }
      }
    }

    &Dropdown {
      display: none;
      box-shadow: 0 4px 16px 0 rgba(0, 41, 69, 0.4);
      background-color: var(--clr-language);
      position: absolute;
      right: 0;
      top: 34px;
      padding: 3px;
      z-index: 1;
      border-radius: 4px;

      @include dark-theme {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.8);
      }

      &Item {
        display: block;
        padding-left: 36px;
        font-size: 0.8125rem;
        line-height: 1.8461538462;
        padding-right: 16px;
        border-radius: 2px;
        color: var(--clr-blue);
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        cursor: pointer;
        width: 100%;
        text-align: left;

        @include dark-theme {
          color: var(--clr-white);
        }

        &--cz {
          background: $ic-lang-cs no-repeat 13px center;
          background-size: 17px 12px;
        }

        &--de {
          background: $ic-lang-de no-repeat 13px center;
          background-size: 17px 12px;
        }

        &--en {
          background: $ic-lang-en no-repeat 13px center;
          background-size: 17px 12px;
        }

        &:hover {
          background-color: rgba(0, 108, 182, 0.16);

          @include dark-theme {
            background-color: rgba(255, 255, 255, 0.24);
          }
        }

        &--disabled {
          color: var(--clr-text-primary);
          cursor: not-allowed;
          opacity: .4;
          filter: grayscale(100%);

          &:hover {
            background-color: transparent;
          }
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -7px;
        left: 50%;
        background: $ic-dropdown-top no-repeat center;
        background-size: 26px 7px;
        width: 26px;
        height: 7px;
        transform: translateX(-50%);

        @include dark-theme {
          background-image: $ic-dropdown-top--dark;
        }
      }
    }
  }

  &__search {
    position: absolute;
    top: 32px;
    right: 0;
    height: $navigation-menu-height;
    z-index: 2;

    &Icon {
      display: block;
      width: 48px;
      height: 48px;
      background: $ic-search no-repeat center;
      background-size: 24px 24px;
      text-indent: -9999px;
      margin: 4px -12px 0 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      cursor: pointer;

      @include dark-theme {
        background-image: $ic-search--dark;
      }
    }

    &Field {
      position: absolute;
      display: none;
      top: 32px;
      left: 0;
      width: 100%;
      height: calc(100% - 32px);
      background: var(--clr-navigation);
      z-index: 3;

      &--opened {
        display: block;
      }

      &Input {
        display: block;
        height: 48px;
        line-height: 14px;
        padding: 17px 24px;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        background: transparent;
        margin: 4px 0;
        width: calc(100% - 64px);
        color: var(--clr-text-primary);
        font-weight: 500;
        font-size: 0.875rem;

        &Label {
          display: none;
        }

        &::-webkit-input-placeholder {
          color: var(--clr-text-tercial);
        }

        &::-moz-placeholder {
          color: var(--clr-text-tercial);
        }

        &:-ms-input-placeholder {
          color: var(--clr-text-tercial);
        }

        &:-moz-placeholder {
          color: var(--clr-text-tercial);
        }
      }

      &Confirm {
        display: none;
      }

      &Close {
        display: block;
        background: $ic-close no-repeat center;
        background-size: 24px 24px;
        width: 48px;
        height: 48px;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        text-indent: -9999px;
        position: absolute;
        top: 4px;
        right: -12px;
        cursor: pointer;

        @include dark-theme {
          background-image: $ic-close--dark;
        }
      }
    }

    &Results {
      display: none;
      position: absolute;
      top: 0;
      left: -1px;
      background: var(--clr-navigation);
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
      width: calc(100% + (100vw - (100% + 212px)) / 2);
      padding: $navigation-height 12px 0 12px;

      @media (max-width: $break-medium) {
        padding-right: 0;
      }

      &--opened {
        display: block;
      }

      &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        height: $navigation-height;
        top: 0;
        left: 0;
        border-bottom: 1px solid var(--clr-separator);
        z-index: 2;
      }

      &Scroll {
        max-height: 400px;
        overflow-y: auto;
        @include scrollbar(4px, auto, var(--clr-blue), var(--clr-blue-darker));
        padding-right: 24px;
      }

      &Shadow {
        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 4px;
          left: 0;
          height: 24px;
          z-index: 1;

          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
          background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */

          @include dark-theme {
            background: -moz-linear-gradient(top, rgba(42, 42, 42, 1) 0%, rgba(42, 42, 42, 0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(42, 42, 42, 1) 0%, rgba(42, 42, 42, 0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(42, 42, 42, 1) 0%, rgba(42, 42, 42, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#292929', endColorstr='#00292929', GradientType=0); /* IE6-9 */
          }
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 4px;
          bottom: 0;
          height: 12px;
          z-index: 1;

          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
          background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */

          @include dark-theme {
            background: -moz-linear-gradient(top, rgba(42, 42, 42, 0) 0%, rgba(42, 42, 42, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(42, 42, 42, 0) 0%, rgba(42, 42, 42, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(42, 42, 42, 0) 0%, rgba(42, 42, 42, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00292929', endColorstr='#292929', GradientType=0); /* IE6-9 */
          }
        }
      }

      &Section {
        display: block;
        border-bottom: 1px solid var(--clr-separator);
        padding: 24px 0 12px;
        max-width: 920px;

        &:last-child {
          border-bottom: none;
        }

        &Title {
          font-size: 1.25rem;
          font-weight: bold;
          line-height: 1.2;
          color: var(--clr-text-primary);
          padding: 0 0 12px 12px;
        }
      }

      &Item {
        display: block;
        padding: 12px;
        border-radius: 4px;
        font-size: 0.875rem;
        line-height: 1.4285714286;
        color: var(--clr-text-primary);

        &:focus, &:hover .Navigation__searchResultsItemTitle {
          text-decoration: underline;
        }

        small {
          display: block;
          font-size: 0.75rem;
          font-weight: bold;
          line-height: 1;
          color: var(--clr-text-tercial);
        }

        &Title {
          display: block;
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.5;
          color: var(--clr-blue);
        }
      }
    }
  }
}


.Navigation--small.Navigation--opened {

  .Navigation__searchResults--opened {
    display: block;
    position: static;
    padding: 0;
    box-shadow: none;
    width: 100%;
    margin: 0;
    background: none;

    &:before {
      display: none;
    }

    .Navigation__searchResultsShadow {

      &:before, &:after {
        display: none;
      }
    }

    .Navigation__searchResultsScroll {
      padding: 0 24px;
      overflow: visible;
      max-height: none;
      border-bottom: 1px solid var(--clr-separator);

      @media (max-width: $break-small) {
        padding: 0 16px;
      }
    }

    .Navigation__searchResultsSection {

      &Title {
        padding: 0;
      }
    }

    .Navigation__searchResultsItem {
      margin: 0 -12px;
    }
  }

  .Navigation__searchField {
    display: block;
    position: relative;
    height: auto;
    width: auto;
    margin: 20px 24px 0;
    top: 0;
    background-color: rgba(42, 51, 55, 0.08);
    border-radius: 4px;

    @include dark-theme {
      background-color: rgba(185, 185, 185, 0.08);
    }

    @media (max-width: $break-small) {
      margin: 20px 16px 0;
    }

    &Input {
      height: 40px;
      padding: 0 0 4px 12px;
      line-height: 20px;
      margin: 0;
      width: calc(100% - 40px);
      border-radius: 0;
    }

    &Close {
      display: none;
    }

    &Confirm {
      display: block;
      background: $ic-search no-repeat center;
      background-size: 24px 24px;
      width: 40px;
      height: 40px;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      text-indent: -9999px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;

      @include dark-theme {
        background-image: $ic-search--dark;
      }
    }
  }

  .Navigation__contentHelper {
    position: absolute;
    top: 68px;
    left: 0;
    right: 0;
    border-top: 1px solid var(--clr-separator);
    height: 1px;
  }

  .Navigation__breadcrumbHelper {
    display: none;
  }

  .Navigation__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 128px;
    @include scrollbar(4px, auto, var(--clr-blue), var(--clr-blue-darker));
  }

  .Navigation__menu {
    visibility: visible;
    float: none;
    width: 100%;
    height: auto;

    & > div {
      float: none;
    }

    &Dropdown {
      float: none;
      display: block;
      width: 100%;
      margin-top: 24px;

      &Container {
        display: block;
        position: static;
        width: 100%;
        height: auto;
        box-shadow: none;
        padding: 0;
        background: none;

        &:before {
          display: none;
        }
      }

      &Shadow {
        &:after, &:before {
          display: none;
        }
      }

      &Scroll {
        overflow: visible;
        padding: 0 24px;

        @media (max-width: $break-small) {
          padding: 0 16px;
        }

        &Content {
          width: 100% !important;
          padding: 0;
        }
      }
    }

    &Item {
      display: inline-block;
      background: var(--clr-red);
      height: 26px;
      line-height: 26px;
      padding: 0 24px;
      font-size: 0.75rem;
      font-weight: bold;
      color: var(--clr-white);
      border-left: none;
      cursor: text;

      @media (max-width: $break-small) {
        padding: 0 16px;
      }

      &:after {
        display: none;
      }
    }

    &Section {
      float: none;
      width: 100%;
      padding: 0;
      margin-top: 16px;

      &Title {
        padding-bottom: 4px;
      }

      &Link {
        display: inline-block;
        margin-right: 24px;
      }
    }
  }

  .Navigation__brand {
    z-index: 1;
    position: relative;
  }

  .Navigation__menuButton {
    margin-right: 16px;
    background: $ic-close no-repeat center;
    background-size: 24px 24px;

    @include dark-theme {
      background-image: $ic-close--dark;
    }

    @media (max-width: $break-small) {
      margin-right: 8px;
    }
  }

  .Navigation__content {
    position: fixed;
    display: block;
    width: 100%;
    height: auto;
    float: none;
    margin: 0;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
}