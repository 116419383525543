.ContentOpening {
  margin-top: 8px;

  &__day {
    display: block;

    &Name {
      display: inline-block;
      min-width: 80px;

      @media (max-width: $break-small) {
        min-width: auto;
        width: 25%;
      }
    }

    &Time {
      display: inline-block;
      min-width: 120px;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5;

      @media (max-width: $break-small) {
        min-width: auto;
        width: 37.5%;
      }
    }

    &--active {
      color: var(--clr-blue);
      font-weight: bold;
    }
  }
}