
.MailSubscriber {
  margin-bottom: 80px;

  &__box {
    display: grid;
    grid-template-columns: auto 432px;
    background: linear-gradient(90deg, #D2E5F2 33%, #006CB6 100%);
    border-radius: 0 4px 4px 4px;
    padding: 24px;
    gap: 80px;

    @include dark-theme {
      background: linear-gradient(90deg, #002E4E 33%, #006CB6 100%);
    }

    @media (max-width: $break-medium) {
      background: linear-gradient(90deg, #D2E5F2 33%, #006CB6 143.25%);
      grid-template-columns: auto;
      gap: 24px;

      @include dark-theme {
        background: linear-gradient(90deg, #002E4E 33%, #006CB6 100%);
      }
    }

    @media (max-width: $break-small) {
      background: linear-gradient(90deg, #D2E5F2 33%, #006CB6 143.25%);
      padding: 16px;
      gap: 16px;

      @include dark-theme {
        background: linear-gradient(90deg, #002E4E 33%, #006CB6 100%);
      }
    }
  }

  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__form {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: $break-small) {
      flex-direction: column;
    }
  }

  &__input {
    appearance: none;
    outline: none;
    background: rgba(255, 255, 255, 0.32);
    border: 1px solid rgba(255, 255, 255, 0.6);
    color: var(--clr-text-primary);
    font-weight: bold;
    border-radius: 8px;
    height: 40px;
    padding: 0 16px;
    min-width: 320px;
    font-size: 16px;

    @include dark-theme {
      background: rgba(255, 255, 255, 0.10);
      color: var(--clr-white);

      &::placeholder {
        color: rgba(255, 255, 255, 0.32);
      }
    }

    @media (max-width: $break-small) {
      min-width: 0;
      width: 100%;
    }
  }

  &__button {
    appearance: none;
    border: none;
    background: var(--clr-red);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.32), inset 0 1px 1px rgba(255, 255, 255, 0.48);
    border-radius: 8px;
    position: relative;
    z-index: 1;
    padding: 8px 16px;
    cursor: pointer;
    color: var(--clr-white);
    font-weight: 700;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    @media (max-width: $break-small) {
      width: 100%;
    }

    &[disabled] {

    }
  }

  &__success {
    font-size: 16px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--clr-white);
    font-weight: bold;

    @media (max-width: $break-medium) {
      justify-content: flex-start;
      color: var(--clr-text-primary)
    }
  }
}
