
.Layout--navigationSmall .ContentAgenda__groupHeader {
  top: 102px;
}

.ContentAgenda {
  background: rgba(0, 108, 182, 0.04);
  border-top: 1px solid #b3dcf8;
  border-bottom: 1px solid #b3dcf8;
  margin: 24px -48px 16px;
  padding: 0 48px;
  position: relative;

  @include dark-theme {
    border-top-color: #004d82;
    border-bottom-color: #004d82;
    background: rgba(0, 108, 182, 0.04);
  }

  @media (max-width: 980px) {
    margin: 24px -24px 0;
    padding: 0 24px;
  }

  @media (max-width: 600px) {
    margin: 24px -16px 0;
    padding: 0 16px;
  }

  &__group {
    border-bottom: 1px solid rgb(228, 229, 232);
    clear: both;

    @include dark-theme {
      border-bottom-color: var(--clr-separator);
    }

    &--opened {
      border-top: 1px solid rgb(228, 229, 232);
      margin: -1px -48px 0;
      padding: 0 48px;

      @include dark-theme {
        border-top-color: var(--clr-separator);
      }

      @media (max-width: 980px) {
        margin: -1px -24px 0;
        padding: 0 24px;
      }

      @media (max-width: 600px) {
        margin: -1px -16px 0;
        padding: 0 16px;
      }

      &:first-child {
        border-top: none;
      }

      .ContentAgenda__groupDate,
      .ContentAgenda__groupTitle {
        color: var(--clr-blue);
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &Header {
      height: 72px;
      top: 88px;
      margin: 1px -48px 0;
      padding: 0 48px;
      position: sticky;
      background: rgba(246, 249, 252, .96);
      z-index: 1;
      cursor: pointer;
      @include clear;

      @include dark-theme {
        background: rgba(37, 40, 43, .96);
      }

      @media (max-width: 980px) {
        margin: 1px -24px 0;
        padding: 0 24px;
      }

      @media (max-width: 600px) {
        margin: 1px -16px 0;
        padding: 0 16px;
      }

      @media (max-width: 480px) {
        height: 56px;
      }
    }

    &Date, &Title {
      font-size: 1.5rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--clr-text-primary);
      padding: 16px 0 20px;

      @media (max-width: 480px) {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    &Date {
      width: 200px;
      float: left;

      @media (max-width: 480px) {
        width: 100%;
        float: none;
      }
    }

    &Title {
      text-transform: capitalize;
      float: left;

      @media (max-width: 480px) {
        display: none;
      }
    }

    &Toggle {
      display: block;
      font-size: 0.75rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.333;
      letter-spacing: normal;
      text-align: right;
      color: var(--clr-blue);
      height: 44px;
      position: absolute;
      right: 48px;
      top: 14px;
      padding: 14px 32px 14px 0;
      cursor: pointer;

      @media (max-width: 980px) {
        right: 24px;
      }

      @media (max-width: 600px) {
        right: 16px;
      }

      @media (max-width: 480px) {
        text-indent: -9999px;
        width: 24px;
        overflow: hidden;
        top: 6px;
      }

      &--opened {
        background: $ic-toggle-close no-repeat center right;
        background-size: 24px 24px;

        @include dark-theme {
          background-image: $ic-toggle-close--dark;
        }
      }

      &--closed {
        background: $ic-toggle-open no-repeat center right;
        background-size: 24px 24px;

        @include dark-theme {
          background-image: $ic-toggle-open--dark;
        }
      }
    }
  }

  &__event {
    display: block;
    border-radius: 4px;
    border: solid 1px rgba(0, 108, 182, 0.16);
    background-color: var(--clr-white);
    margin-bottom: 8px;
    padding: 20px;
    position: relative;
    @include clear;

    @include dark-theme {
      background-color: rgba(255, 255, 255, 0.12);
      border-color: rgba(255, 255, 255, 0.24);
    }

    @media (max-width: 480px) {
      padding: 16px;
    }

    &--past {
      border: solid 1px #ebebeb;
      background-color: rgba(255, 255, 255, 0.56);

      @include dark-theme {
        border-color: var(--clr-separator);
        background-color: transparent;
      }

      .ContentAgenda__eventTime,
      .ContentAgenda__eventTitle {
        color: rgba(0, 0, 0, 0.16);

        @include dark-theme {
          color: rgba(255, 255, 255, 0.16);
        }
      }

      .ContentAgenda__eventInfo {
        opacity: .24;
      }
    }

    &--opened {
      border-radius: 4px;
      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.24);
      border: solid 1px rgba(0, 108, 182, 0.16);
      margin: 16px -16px 16px;

      @include dark-theme {
        background-color: rgba(255, 255, 255, 0.24);
        border-color: #3cb0ff;
      }

      @media (max-width: 480px) {
        border-radius: 0;
      }

      &:first-child {
        margin-top: 0;
      }

      .ContentAgenda__eventInfo {
        background-image: $ic-agenda-close;
        text-indent: -9999px;
        color: transparent;
        width: 24px;
        overflow: hidden;

        @include dark-theme {
          background-image: $ic-agenda-close--dark;
        }
      }

      .ContentAgenda__eventTime {
        width: 196px;
        padding-left: 20px;

        @media (max-width: 660px) {
          width: auto;
          padding-left: 16px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
        }
      }

      .ContentAgenda__eventHeaderContent {
        width: calc(100% - 196px);

        @media (max-width: 660px) {
          width: 100%;
          padding-left: 16px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
        }
      }
    }

    &List {
      display: block;
      padding-bottom: 16px;
      transition: all ease-in-out 200ms;
    }

    &Title {
      font-size: 1.125rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.333;
      letter-spacing: normal;
      color: var(--clr-text-primary);
    }

    &Description {
      display: block;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.333;
      letter-spacing: normal;
      color: rgba(48, 58, 66, 0.76);
      padding: 4px 0 0 0;

      @include dark-theme {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    &Location {
      display: inline-block;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.333;
      letter-spacing: normal;
      color: rgba(48, 58, 66, 0.76) !important;
      background: $ic-agenda-location no-repeat top 1px left;
      background-size: 10px 12px;
      padding: 0 0 0 16px;
      margin-top: 6px;
      cursor: pointer;

      @include dark-theme {
        background-image: $ic-agenda-location--dark;
      }

      &:hover {
        text-decoration: none !important;
      }

      &Name {
        color: var(--clr-blue);
        padding-right: 4px;

        @media (max-width: 480px) {
          display: block;
        }
      }

      &Direction {

        &:before {
          content: "(";

          @media (max-width: 480px) {
            display: none;
          }
        }

        &:after {
          content: ")";

          @media (max-width: 480px) {
            display: none;
          }
        }
      }
    }

    &Info {
      display: block;
      appearance: none;
      border: none;
      background: $ic-agenda-info no-repeat center right;
      background-size: 24px 24px;
      cursor: pointer;
      position: relative;
      width: 24px;
      min-width: 24px;
      height: 24px;
      text-indent: -9999px;

      @include dark-theme {
        background: $ic-agenda-info--dark;
      }

      &:active {
        opacity: .7;
      }
    }

    &Time {
      min-width: 180px;
      padding: 4px;
      font-size: 1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: var(--clr-text-primary);
      font-family: 'Ubuntu Mono', monospace;

      @media (max-width: 660px) {
        float: none;
        width: auto;
        padding: 0;
      }

      &Now {
        display: block;
        font-family: $font-default;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.333;
        letter-spacing: normal;
        color: var(--clr-blue);
        padding-top: 8px;

        @media (max-width: 660px) {
          display: inline;
          padding-left: 8px;
        }

        &--past {
          color: var(--clr-text-tercial);
        }
      }
    }

    &Header {
      display: flex;

      @media (max-width: 660px) {
        flex-direction: column;
      }
    }

    &Container {
      display: flex;
      justify-content: space-between;
    }

    &Content {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      margin: 18px 0 0 196px;
      padding-top: 8px;

      @media (max-width: 1190px) {
        margin-left: 16px;
      }

      @media (max-width: 980px) {
        margin-left: 196px;
      }

      @media (max-width: 900px) {
        margin-left: 16px;
      }

      @media (max-width: 480px) {
        margin-left: 0;
      }

      .ContentImage--single {
        width: 100%;
        max-height: none;
        border: 1px solid var(--clr-separator);

        @media (max-width: $break-small) {
          margin-left: 0;
        }
      }
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    z-index: 2;
    appearance: none;
    border: none;
    height: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 16px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
    border-radius: 16px;
    padding: 0 16px;
    transform: translateX(-50%);
    cursor: pointer;
    outline: none;
    transition: transform ease-in-out 250ms;
    will-change: transform;
    color: var(--clr-blue);
    background: var(--clr-language);
    white-space: nowrap;

    @media (max-width: $break-small) {
      height: 24px;
    }

    @include dark-theme {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    }

    &:active {
      transform: translateX(-50%) scale3d(0.97, 0.97, 1);
    }

    &--older {
      top: -16px;

      @media (max-width: $break-small) {
        top: -12px;
      }
    }
  }
}
