.UsefulLinks {
  width: 354px;
  float: left;

  @media (max-width: $break-large) {
    width: 330px;
  }

  @media (max-width: $break-medium) {
    width: 100%;
  }

  &__content {
    margin: 24px;

    @media (max-width: $break-medium) {
      margin: 24px -8px 0;
      @include clear();
    }
  }

  &__item {
    display: block;
    width: 100%;
    cursor: pointer;
    padding-bottom: 16px;

    @media (max-width: $break-medium) {
      width: 50%;
      float: left;
      padding: 0 8px 16px;
    }

    @media (max-width: $break-small) {
      width: 100%;
      float: none;
    }

    &:hover .UsefulLinks__title {
      text-decoration: underline;
    }
  }

  &__title {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--clr-blue);
    padding-bottom: 4px;

    &--external {
      position: relative;
      padding-right: 20px;

      &:after {
        content: "";
        display: block;
        background: $ic-link no-repeat center;
        background-size: 12px 12px;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 6px;
      }
    }
  }

  &__description {
    display: block;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--clr-text-secondary);
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include dark-theme {
      color: var(--clr-text-tercial);
    }
  }
}