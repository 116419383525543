@mixin clear {

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin noscrollbar() {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background: inherit;
    width: 0;
    height: 0;
    border: none;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover::-webkit-scrollbar-thumb {
    height: 0;
    background-color: transparent;
    border: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: transparent;
    border: none;
  }
}

@mixin scrollbar($width: 4px, $height: auto, $color: black, $darkenColor: black) {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background: inherit;
    width: $width;
    height: $height;
    border: none;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover::-webkit-scrollbar-thumb {
    height: $height;
    background-color: $color;
    border: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $darkenColor;
    border: none;
  }
}

@mixin dark-theme {
  @media (prefers-color-scheme: dark) {
    @content
  }
}
