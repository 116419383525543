@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:700&display=swap');

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-default;
  font-weight: 400;
  quotes: "“" "”";
  color: var(--clr-text-primary);
  font-size: 16px;
}

body, input, textarea, select, button {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}