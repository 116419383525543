@import "mapbox-gl/dist/mapbox-gl.css";

@import "variables";
@import "typography";
@import "mixins";
@import "layout";
@import "flag";
@import "outdatedbrowser";
@import "noscript";

@import "components/error";
@import "components/footer";
@import "components/navigation";

@import "components/hero";
@import "components/instagramFeed";
@import "components/instagram";
@import "components/list";
@import "components/map";

@import "components/pageContent";
@import "components/newsContent";
@import "components/newsList";

@import "components/pickedPages";
@import "components/plainContent";
@import "components/recommend";
@import "components/sidebar";
@import "components/usefulLinks";
@import "components/guestCard";

@import "components/contentAgenda";
@import "components/contentAlert";
@import "components/contentContact";
@import "components/contentFiles";
@import "components/contentImage";
@import "components/contentOpening";
@import "components/contentRouteValidator";
@import "components/mailSubscriber";

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.html--loaded body {
  overflow-x: hidden;
  min-height: 100vh;
  background: var(--clr-background);
  background: linear-gradient(0deg, #ffffff 0, #ffffff 25%, #006cb6 0, #006cb6);

  @include dark-theme {
    background: var(--clr-background);
    background: linear-gradient(0deg, #000000 0, #000000 25%, #002e4e 0, #002e4e);
  }
}

body {
  // background: var(--clr-background);
}
