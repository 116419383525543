.Navigation__wrapper--small + .Hero {
  margin-top: -50px;
}

.Hero {
  display: block;
  min-height: 392px;
  background: var(--clr-text-secondary) no-repeat center;
  background-size: cover;
  margin-top: -88px;
  position: relative;
  padding-bottom: 156px;
  z-index: 0;
  overflow: hidden;

  @media (max-width: $break-medium) {
    min-height: 352px;
    padding-bottom: 0;
  }

  @media (max-width: $break-small) {
    min-height: auto;
  }

  &.Hero--fullscreen {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }

    .Container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 40px;
      padding: 0;

      h1 {
        display: none;
      }

      .Hero__button {
        float: left;

        &List {
          bottom: 0;
          left: 0;
          width: auto;
        }
      }
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  & > .Container {
    position: relative;
    z-index: 1;
  }

  h1 {
    text-shadow: 0 0 24px rgba(0, 0, 0, 0.24);
    font-size: 7.5rem;
    font-weight: bold;
    line-height: 1;
    color: var(--clr-white);
    z-index: 2;
    position: relative;
    padding: 114px 0 0 236px;

    @media (max-width: $break-medium) {
      text-shadow: 0 0 24px rgba(0, 0, 0, 0.24);
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.1;
      text-align: center;
      padding: 88px 0 176px;
    }

    @media (max-width: $break-small) {
      font-size: 3rem;
      line-height: 1.1666666667;
      padding: 40px 0;
    }
  }

  &__button {
    display: block;
    appearance: none;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    border: none;
    background: none;
    cursor: pointer;
    float: right;

    &--fullscreen {
      background: $ic-fullscreen no-repeat center;
      background-size: 24px 24px;
    }

    &:active {
      opacity: .7;
    }

    &List {
      display: block;
      width: 236px;
      @include clear();
      position: absolute;
      bottom: -156px;
      left: $container-padding-xlarge;

      @media (max-width: 1440px) {
        left: $container-padding-large;
      }

      @media (max-width: $break-medium) {
        display: none;
      }
    }
  }

  iframe {
    position: absolute;
    pointer-events: none;
    left: 0;
    width: 100%;
    height: 300%;
    top: -100%;

    &.Video--fullscreen {
      pointer-events: auto;
    }
  }

  &--center {
    text-align: center;

    h1 {
      padding-left: 0;
    }
  }

  &--light {
    background: var(--clr-blue-light);
    min-height: 492px;
    margin-bottom: -156px;
    overflow: visible;

    @media (max-width: $break-medium) {
      min-height: 422px;
      padding-bottom: 0;
    }

    @media (max-width: $break-small) {
      min-height: auto;
      margin-bottom: 16px;
    }

    h1 {
      color: var(--clr-text-primary);
      text-shadow: none;
    }

    &:before {
      display: none;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 14px;
      background: $ic-wave-light repeat-x;
      background-size: 55px 14px;
      position: absolute;
      bottom: -14px;
      left: 0;

      @include dark-theme {
        background-image: $ic-wave-light--dark;
      }
    }
  }
}