

.Flag {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4285714286;
  text-align: center;
  color: var(--clr-white);
  background: var(--clr-red);
  text-transform: uppercase;
  padding: 7px 24px 5px;

  @media (max-width: $break-small) {
    font-size: 0.75rem;
    height: 26px;
    padding: 4px $container-padding-small 2px;
    margin-left: -16px;
  }
}