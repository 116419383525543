
.ContentAlert {
  display: block;
  margin: 0 -48px;
  background: rgba(227, 6, 19, 0.16) $ic-warning no-repeat center 35px;
  background-size: 48px 48px;
  margin-top: 24px;
  padding: 100px 48px 40px;
  text-align: center;

  @media (max-width: $break-medium) {
    margin: 0 -24px;
  }

  @media (max-width: $break-small) {
    margin: 0 -16px;
    padding: 56px 16px 16px;
    background-size: 24px 24px;
    background-position: center 24px;

    p {
      font-size: 0.8125rem;
      line-height: 1.5384615385;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: var(--clr-red);
    margin: 0;
  }
}